
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Button, Popover } from 'ant-design-vue';
import {
    determineIfProductCategoryMustBeSimple,
    transformProducts,
    transformProductSystems,
} from '@/helpers/Products/ProductHelper';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { RouteNames } from '@/enums/routes/RouteNames';
import MultiplierModal from '@/components/views/NewProjectView/Offer/MultiplierModal.vue';

@Component({
    name: 'CalculatorProductSelector',
    components: { Popover, Button, MultiplierModal },
})
export default class CalculatorProductSelector extends Vue {
    @Prop({ default: false }) private shouldPickerBeLocked!: boolean;
    @Prop({ required: true }) private productCategoryId!: string;
    @Prop({ default: null }) private clientId!: string | null;
    @Prop({ default: null }) private clientsPaymentTypeId!: string | null;
    @Prop({ default: null }) private selectedOfferId!: string | null;
    @Prop({ required: true }) private projectId!: string;

    private get productsByCategoryId() {
        if (this.productCategoryId === null) {
            return [];
        }

        const categoryById = ProductCategoriesRepository.getById(this.productCategoryId);
        if (categoryById == null) {
            return [];
        }

        return categoryById.products;
    }
    private get itemsToDisplay() {
        if (determineIfProductCategoryMustBeSimple(this.productCategoryId)) {
            return transformProductSystems(this.productSystems);
        }
        return transformProducts(this.productsByCategoryId);
    }

    private get productSystems() {
        return this.productsByCategoryId
            .map((product) => {
                return product.productSystems;
            })
            .flat();
    }

    private productClick(product: any) {
        this.$router.push({
            name: RouteNames.calculatorProduct,
            params: { productId: product.productId, productFormId: product.productFormId },
            query: {
                projectId: this.projectId,
                offerId: this.selectedOfferId,
                clientId: this.clientId,
                productSystemName: product.isProductCustom ? null : product.productSystemCode,
            },
        });
    }
}
