
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductSearch from '@/components/views/NewProjectView/Offer/ProductSearch.vue';
import { Radio } from 'ant-design-vue';
import SearchMaterials from '@/components/views/project/SearchMaterials.vue';
import OfferItem from '@/models/OfferItem';
import { ProductAndMaterialSearchStates } from '@/enums/components/ProjectNew/ProductAndMaterialSearchStates';

@Component({
    name: 'ProductAndMaterialSearch',
    components: {
        ProductSearch,
        RadioGroup: Radio.Group,
        RadioButton: Radio.Button,
        SearchMaterials,
    },
})
export default class ProductAndMaterialSearch extends Vue {
    @Prop({required: true}) private projectId!: string;
    @Prop({required: true}) private selectedOfferId!: string | null;
    @Prop({required: true}) private clientsPaymentTypeId!: string | null;
    @Prop({required: true}) private offerItems!: OfferItem[];
    @Prop({default: false}) private showOnlyMaterialSearch!: boolean;

    private productAndMaterialSearchStates = ProductAndMaterialSearchStates;

    private state = this.showOnlyMaterialSearch ?
        ProductAndMaterialSearchStates.MATERIALS : ProductAndMaterialSearchStates.PRODUCTS;
}
