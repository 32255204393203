
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppSidebar from '@/components/global/sidebar/AppSidebar.vue';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ProductCategory from '@/models/ProductCategory';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import CalculatorProductSelector from '@/components/views/Calculator/CalculatorProductSelector.vue';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { generateProductCategoryOptions } from '@/helpers/Products/ProductHelper';
import { Tabs } from 'ant-design-vue';
import ProductAndMaterialSearch from '@/components/views/NewProjectView/Offer/ProductAndMaterialSearch.vue';

@Component({
    name: 'CalculatorMainSection',
    components: {
        AppSidebar,
        CalculatorProductSelector,
        Tabs,
        TabPane: Tabs.TabPane,
        ProductAndMaterialSearch,
    },
})
export default class CalculatorMainSection extends Vue {
    private hasUnsavedData = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    @Prop({ default: false }) private isLoading!: boolean;

    private selectedProductCategory = '';

    private async fetchAllRecords(entity: any) {
        await entity.getAll();
    }

    private updateUnsavedDataState({ state }: { state: boolean }) {
        this.hasUnsavedData = state;
    }

    private async mounted() {
        this.loadingOverlay.start();

        EventBus.$on(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
        try {
            await ProductCategory.getAll();
            this.loadingOverlay.stop();
        } catch (e) {
            return;
        }
    }

    private get productCategories() {
        return ProductCategoriesRepository.getAll();
    }

    private get isSeletedCategoryMaterials() {
        if (this.selectedProductCategory == null) {
            return false;
        }

        const categoryById = ProductCategoriesRepository.getById(this.selectedProductCategory);

        if (categoryById == null) {
            return false;
        }

        return categoryById.code === 'MATERIALS';
    }

    private get productCategoryOptions(): RadioButtonGroupOptions[] {
        return generateProductCategoryOptions(this.productCategories, true);
    }

    @Watch('productCategoryOptions', { deep: true })
    private onProductCategoryOptionsChange() {
        if (this.productCategoryOptions.length > 0) {
            this.selectedProductCategory = this.productCategoryOptions[0].value;
        }
    }
}
