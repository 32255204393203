
import { Component, Vue } from 'vue-property-decorator';
import { Button, Divider, Icon, Select, Spin } from 'ant-design-vue';
import Product from '@/models/Product';

@Component({
    name: 'ProductSearch',
    components: {
        Select,
        Spin,
        SelectOption: Select.Option,
        Divider,
        Button,
        Icon,
        VNodes: {
            functional: true,
            // @ts-ignore
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
})
export default class ProductSearch extends Vue {
    private data = [];

    private fetching = false;

    private async fetchProducts(value: string) {
        this.fetching = true;
        this.data = [];

        const response = await Product.searchByNameAndProductSystemName(value);

        this.fetching = false;

    }

    private handleChange() {
        // todo - set active product id to a variable and use it when user tries adding the product
    }

}
