
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InputNumber, Modal } from 'ant-design-vue';

@Component({
    name: 'MultiplierModal',
    components: { Modal, InputNumber },
})
export default class MultiplierModal extends Vue {
    @Prop({ default: 1 }) private timesToMultiply!: number;
    @Prop({ default: false }) private isMultiplierModalVisible!: boolean;

    private closeMultiplierPopup() {
        this.$emit('update:isMultiplierModalVisible', false);
    }
}
